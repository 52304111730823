import React, { FC, useMemo } from 'react';

import { IFilter } from '@src/types/filter';
import { IAnomalyFilter } from '@src/types/operational_dashboard/audit_scan';

import Filter from '@src/components/ui/filter';
import { DummyIcon } from '@src/components/utils/fa_icons';

interface IAnomaliesFilterProps {
  filter: IFilter<IAnomalyFilter>;
}

const AnomaliesFilter: FC<IAnomaliesFilterProps> = ({ filter }) => {
  const showTypeOptions = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
      {
        value: 'anomalies',
        label: 'Anomalies only',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
    ],
    [],
  );

  const anomalyTypeOptions = useMemo(
    () => [
      {
        value: '',
        label: 'All Types',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
      {
        value: 'vendor',
        label: 'Vendor',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
      {
        value: 'chart_of_account',
        label: 'Chart of Account',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
      {
        value: 'accounting_class',
        label: 'Department',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
      {
        value: 'transaction_type',
        label: 'Accounting Type',
        icon:  <DummyIcon fontSize={ 17 } />,
      },
    ],
    [],
  );

  return (
    <Filter filter={ filter }>
      <Filter.IconSelectField
        name="showType"
        options={ showTypeOptions }
        placeholder="Show"
        widthPercent={ 12 }
      />
      <Filter.DateField name="date" widthPercent={ 20 } />
      <Filter.TextField name="type" placeholder="Type" widthPercent={ 10 } />
      <Filter.TextField
        name="description"
        placeholder="Memo/Description"
        widthPercent={ 10 }
      />
      <Filter.TextField
        name="vendorName"
        placeholder="Name"
        widthPercent={ 10 }
      />
      <Filter.TextField
        name="accountingClassName"
        placeholder="Department"
        widthPercent={ 10 }
      />
      <Filter.TextField
        name="chartOfAccountName"
        placeholder="Chart of Account"
        widthPercent={ 10 }
      />
      <Filter.AmountField name="amount" widthPercent={ 15 } />
      <Filter.IconSelectField
        isClearable
        isSearchable
        name="anomalyType"
        options={ anomalyTypeOptions }
        placeholder="Anomaly Type"
        widthPercent={ 15 }
      />
    </Filter>
  );
};

export default AnomaliesFilter;
