import React, { useMemo, useState, useEffect } from 'react';

import toastr from '@lib/toastr';
import { useGetBooksGptPostOnboardingState } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { reopenBooks } from '@src/requests/operational_dashboard/audit_scan';
import { TBooksGptPostOnboardingState } from '@src/types/businesses';
import { IMonthEndClosingProps } from '@src/types/common_operational_dashboard';
import { Meta } from '@src/types/operational_dashboard/audit_scan';
import { formatDate } from '@src/utils/date_helpers';

import NumberBox from '@src/components/operational_dashboard/components/custom_cells/number_box';
import {
  auditScanTooltipData,
  missingBankStatementTooltipData,
} from '@src/components/operational_dashboard/helper';
import { ICommonProps } from '@src/components/operational_dashboard/types';
import { Button } from '@src/components/ui_v2/buttons';
import { NotepadIcon } from '@src/components/utils/icomoon';

import AuditScanItem from './audit_scan_item';
import HoverTooltipWrapper from './hover_tooltip_wrapper';
import ReconciliationItem from './reconciliation_item';
import { useMarkBookAsClosedModal } from '../mark_book_as_closed';

import styles from './style.module.scss';

type IMonthEndClosingCellProps = IMonthEndClosingProps & ICommonProps & {
  handleNotesViewClick: () => void;
  booksGptPostOnboardingState?: TBooksGptPostOnboardingState;
  booksGptPostOnboardingMeta?: Meta;
  lastCloseDate?: string;
};

const MonthEndClosingCell = ({
  missingBankStatements,
  auditScan,
  auditScanStatus,
  lastAuditScanDate,
  notes,
  preferences,
  setupStatus,
  handleNotesViewClick,
  disconnectedGeneralLedger,
  auditId,
  businessId,
  businessName,
  lastBookLockDate,
  lastCloseDate,
  auditStartDate,
  businessIntent,
  booksGptPostOnboardingState,
  booksGptPostOnboardingMeta,
}: IMonthEndClosingCellProps) => {
  const {
    Component: MarkBookAsClosedModal,
    props: markBookAsClosedModalProps,
    openWithValue: openMarkBookAsClosedModalWithValue,
  } = useMarkBookAsClosedModal();

  const [isReopeningBooks, setIsReopeningBooks] = useState(false);
  const [localBooksGptState, setLocalBooksGptState] = useState<TBooksGptPostOnboardingState | undefined>(
    booksGptPostOnboardingState,
  );

  // Use local state that can be updated after reopening books
  const effectiveBooksGptState = localBooksGptState ?? booksGptPostOnboardingState;

  // Update local state when props change
  useEffect(() => {
    if (booksGptPostOnboardingState) {
      setLocalBooksGptState(booksGptPostOnboardingState);
    }
  }, [booksGptPostOnboardingState]);

  // Get the last books lock date from the meta field if available, otherwise fallback to lastBookLockDate prop
  const effectiveLastBooksLockDate = booksGptPostOnboardingMeta?.lastBooksLockDate ?? lastBookLockDate;

  // Get the last close date from the meta field if available, otherwise fallback to lastCloseDate prop
  const effectiveLastCloseDate = booksGptPostOnboardingMeta?.lastCloseDate ?? lastCloseDate;

  const renderGeneralLedgerConnectionIssues = useMemo(() => {
    if (disconnectedGeneralLedger) {
      return <NumberBox value={ { value: true, type: 'indicator' } } />;
    }
    return <NumberBox value={ { value: 0, type: 'number' } } />;
  }, [disconnectedGeneralLedger]);

  const auditScanCount = useMemo(() => {
    return auditScan ?? 0;
  }, [auditScan]);

  // Determine if the "Mark Books As Closed" button should be visible
  const showMarkBooksAsClosed = useMemo(() => {
    // Check if state is ready, scan_failed, or reset_failed
    return effectiveBooksGptState === 'ready'
      || effectiveBooksGptState === 'scan_failed'
      || effectiveBooksGptState === 'reset_failed';
  }, [effectiveBooksGptState]);

  // Determine if the "Reopen Books" button should be visible
  const showReopenBooks = useMemo(() => {
    // Check if books are locked (lastBooksLockDate is not null)
    const booksAreLocked = !!effectiveLastBooksLockDate;

    // Check if state is ready or any failed state
    const isReadyOrFailed = effectiveBooksGptState === 'ready'
      || effectiveBooksGptState === 'scan_failed'
      || effectiveBooksGptState === 'close_failed'
      || effectiveBooksGptState === 'learn_failed'
      || effectiveBooksGptState === 'reset_failed';

    return booksAreLocked && isReadyOrFailed;
  }, [effectiveLastBooksLockDate, effectiveBooksGptState]);

  // Add the query client for refetching
  const { refetch: refetchBooksGptState } = useGetBooksGptPostOnboardingState(
    businessId as number,
    {
      enabled:         !!businessId,
      onSuccess: (data) => {
        if (data && data.state) {
          setLocalBooksGptState(data.state as TBooksGptPostOnboardingState);
        }
      },
    },
    false, // Don't poll automatically
  );

  const handleReopenBooks = async () => {
    if (!businessId) return;

    try {
      // Set loading state until API responds
      setIsReopeningBooks(true);

      // Temporarily set the local state for immediate UI feedback
      setLocalBooksGptState('reset_in_progress' as TBooksGptPostOnboardingState);

      // Send the PUT request to reopen books
      const response = await reopenBooks({ businessId });

      // Update the state based on the actual API response
      if (response && response.state) {
        // Set the local state to match the API response
        setLocalBooksGptState(response.state as TBooksGptPostOnboardingState);

        // Explicitly fetch the latest state from the server
        await refetchBooksGptState();

        // Notify other components about the state change
        if (window.Docyt && window.Docyt.vent) {
          // Use a business-specific event name
          window.Docyt.vent.trigger(`business:${businessId}:state:updated`);
        }
      }
    } catch (error) {
      console.error('Error reopening books:', error);

      // Only show error message if there's an actual error
      toastr.error('Failed to reopen books. Please try again.', 'Error');

      // In case of error, reset the state back
      setLocalBooksGptState(booksGptPostOnboardingState);
    } finally {
      // Always turn off the loading button state after API call completes
      setIsReopeningBooks(false);
    }
  };

  // Helper function to render the last close date with or without a tooltip
  const renderLastCloseDate = () => {
    // Check if the business is currently in reopening books state
    const isReopeningInProgress = effectiveBooksGptState === 'reset_in_progress';

    // Show tooltip only if we have actions available and NOT in reset_in_progress state
    if ((showMarkBooksAsClosed || showReopenBooks) && !isReopeningInProgress) {
      return (
        <HoverTooltipWrapper
          content={ (
            <div className={ styles['hover-tooltip-content-last-close-date'] }>
              <div className={ styles['tooltip-button-container'] }>
                {showMarkBooksAsClosed && (
                  <div className={ styles['tooltip-button'] }>
                    <Button
                      size="compact"
                      variant="link"
                      onClick={ () => {
                        openMarkBookAsClosedModalWithValue({
                          auditId:          auditId ?? 0,
                          businessId:       businessId ?? 0,
                          lastBookLockDate: effectiveLastBooksLockDate ?? '',
                          auditStartDate:   auditStartDate ?? '',
                        });
                      } }
                      className={ styles['compact-button'] }
                    >
                      Mark Books As Closed
                    </Button>
                  </div>
                )}
                {showReopenBooks && (
                  <div className={ styles['tooltip-button'] }>
                    <Button
                      size="compact"
                      variant="link"
                      onClick={ handleReopenBooks }
                      className={ styles['compact-button'] }
                      disabled={ isReopeningBooks }
                    >
                      {isReopeningBooks ? 'Reopening...' : 'Reopen Books'}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) }
        >
          {effectiveLastCloseDate
            ? formatDate(effectiveLastCloseDate)
            : <NumberBox value={ { value: 0, type: 'number' } } />}
        </HoverTooltipWrapper>
      );
    }

    // No actions available or in reset_in_progress, just show the date without a tooltip
    return effectiveLastCloseDate
      ? formatDate(effectiveLastCloseDate)
      : <NumberBox value={ { value: 0, type: 'number' } } />;
  };

  return (
    <div className={ `${styles['business-reconciliation-container']} ${styles['expense-header-container']}` }>
      <MarkBookAsClosedModal
        { ...markBookAsClosedModalProps }
      />
      {preferences?.monthEndClosing?.missingBankStatements
        && (missingBankStatements != null ? (
          <div className={ styles['header-item'] }>
            <ReconciliationItem
              businessId={ businessId }
              setupStatus={ setupStatus }
              tooltipData={ missingBankStatementTooltipData }
              tooltipValue={ missingBankStatements }
              value={ missingBankStatements }
            />
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            {renderGeneralLedgerConnectionIssues}
          </div>
        ))}
      {preferences?.monthEndClosing?.auditScan && (auditScan != null ? (
        <div className={ styles['header-item'] }>
          {businessIntent === window.configData.business_intent.FULL_BOOKKEEPING
            || businessIntent === window.configData.business_intent.PREMIUM_FIRM_CLOSING_SUITE ? (
              <NumberBox value={ { value: 0, type: 'number' } } />
            ) : (
              <AuditScanItem
                auditScanStatus={ auditScanStatus ?? 'completed' }
                businessId={ businessId }
                businessName={ businessName }
                disconnectedGeneralLedger={ disconnectedGeneralLedger ?? false }
                lastAuditedOn={ formatDate(lastAuditScanDate) }
                tooltipData={ auditScanTooltipData }
                value={ auditScanCount }
              />
            )}
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          {renderGeneralLedgerConnectionIssues}
        </div>
      ))}
      {preferences?.monthEndClosing?.lastCloseDate && (
        !disconnectedGeneralLedger ? (
          <div className={ styles['header-item'] }>
            {businessIntent === window.configData.business_intent.FULL_BOOKKEEPING
              || businessIntent === window.configData.business_intent.PREMIUM_FIRM_CLOSING_SUITE ? (
                <NumberBox value={ { value: 0, type: 'number' } } />
              ) : (
                // Determine what to render based on available actions and data
                renderLastCloseDate()
              )}
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            <NumberBox value={ { value: true, type: 'indicator' } } />
          </div>
        )
      )}

      { preferences?.monthEndClosing?.notes && (
        <div className={ styles['header-item'] }>
          { notes != null ? (
            <HoverTooltipWrapper
              content={ (
                <div className={ styles['hover-tooltip-content-last-close-date'] }>
                  <Button
                    size="compact"
                    variant="link"
                    onClick={ handleNotesViewClick }
                  >
                    View Notes Log
                  </Button>
                </div>
                ) }
            >
              <NotepadIcon fontSize={ 16 } onClick={ handleNotesViewClick } />
            </HoverTooltipWrapper>
          ) : (
            <NumberBox value={ { value: 0, type: 'number' } } />
          ) }
        </div>
      ) }

    </div>
  );
};
export default MonthEndClosingCell;
