import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse,
  IDeleteManagementGroupParams,
  IAddManagementGroupParams,
  IUpdateManagementGroupParams,
  IGetManagementGroupByBusinessIdResponse,
  IGetManagementGroupResponse,
  getManagementGroups,
  deleteManagementGroup,
  addManagementGroup,
  updateManagementGroup,
  getManagementGroupByBusinessId,
  getManagementGroup,
  getManagementGroupsWithNotificationSetting,
  getManagementDefaultAggregator,
  IGetManagementGroupDefaultAggregatorResponse,
  IGetManagementDefaultAggregatorParam,
  IGetAccessibleManagementGroupsParams,
  getAccessibleManagementGroups,
  IGetAccessibleManagementGroupsResponse,
  IJoinManagementGroupParams,
  ILeaveManagementGroupParams,
  joinManagementGroup,
  leaveManagementGroup,
} from '@src/requests/management_groups';
import { TID } from '@src/types/common';
import { IManagementGroup } from '@src/types/management_groups';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const MANAGEMENT_GROUPS_QUERY = QueryKey.managementGroups;
const MANAGEMENT_GROUP_BY_BUSINESS_ID_QUERY = 'management_group_by_business_id';

const useGetManagementGroups = createUseGetInfiniteCollection<
  IManagementGroup,
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse
>({
  queryKey: MANAGEMENT_GROUPS_QUERY,
  request:  getManagementGroups,
});

const useGetManagementGroupsWithNotificationSetting = createUseGetInfiniteCollection<
  IManagementGroup,
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse
  >({
    queryKey: QueryKey.managementGroupsWithNotificationSetting,
    request:  getManagementGroupsWithNotificationSetting,
  });

const useGetManagementGroup = (id?: TID) => {
  return useQuery<IGetManagementGroupResponse, Error>(
    [QueryKey.managementGroupById, id],
    () => getManagementGroup(id),
    {
      enabled: Boolean(id),
    },
  );
};

const useGetManagementGroupByBusinessId = (businessId?: TID) => {
  return useQuery<IGetManagementGroupByBusinessIdResponse, Error>(
    [MANAGEMENT_GROUP_BY_BUSINESS_ID_QUERY, businessId],
    () => getManagementGroupByBusinessId(businessId),
    {
      enabled: Boolean(businessId),
    },
  );
};

const useDestroyManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteManagementGroupParams>(
    deleteManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useAddManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IAddManagementGroupParams>(
    addManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useUpdateManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateManagementGroupParams>(
    updateManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        queryClient.invalidateQueries([QueryKey.managementGroupUsers]);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useGetManagementGroupDefaultAggregator = () => {
  const queryClient = useQueryClient();

  return useMutation<IGetManagementGroupDefaultAggregatorResponse, Error, IGetManagementDefaultAggregatorParam>(
    getManagementDefaultAggregator,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useGetAccessibleManagementGroups = (
  params: IGetAccessibleManagementGroupsParams,
  opts?: UseQueryOptions<IGetAccessibleManagementGroupsResponse, Error & AxiosError>,
) => {
  return useQuery<IGetAccessibleManagementGroupsResponse, Error & AxiosError>(
    MANAGEMENT_GROUPS_QUERY,
    () => getAccessibleManagementGroups(params),
    {
      ...opts,
    },
  );
};

const useJoinManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IJoinManagementGroupParams>(
    joinManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('management_groups:updated');
      },
    },
  );
};

const useLeaveManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ILeaveManagementGroupParams>(
    leaveManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('management_groups:updated');
      },
    },
  );
};

export {
  useGetManagementGroups,
  useGetManagementGroupsWithNotificationSetting,
  useDestroyManagementGroup,
  useAddManagementGroup,
  useUpdateManagementGroup,
  useGetManagementGroupByBusinessId,
  useGetManagementGroup,
  useGetManagementGroupDefaultAggregator,
  useGetAccessibleManagementGroups,
  useJoinManagementGroup,
  useLeaveManagementGroup,
};
