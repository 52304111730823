/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import classNames from 'classnames';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { apiGet, apiPost } from '@src/requests/helpers';
import { TID } from '@src/types/common';
import { IAccessibleManagementGroup } from '@src/types/management_groups';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import Modal from '@src/components/ui/modal';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';
import { SearchIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IManageGroupsModalProps extends IUseModalProps {}

interface IGroupActionProps {
  managementGroup: IAccessibleManagementGroup;
  isJoined: boolean;
  onJoin: (id: TID) => Promise<void>;
  onLeave: (id: TID) => Promise<void>;
}

const GroupAction = ({ managementGroup, isJoined, onJoin, onLeave }: IGroupActionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  // Set isMounted to false when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAction = async () => {
    setIsLoading(true);
    try {
      if (isJoined) {
        await onLeave(managementGroup.id);
      } else {
        await onJoin(managementGroup.id);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const groupActionClass = classNames(
    styles['group-action'],
    {
      [styles['leave-group']]: isJoined,
      [styles['join-group']]:  !isJoined,
    },
  );

  // Fix nested ternary by using separate variables
  let buttonContent;
  if (isLoading) {
    buttonContent = <ProgressActivityLoader size="small" />;
  } else if (isJoined) {
    buttonContent = 'Leave Group';
  } else {
    buttonContent = 'Join Group';
  }

  return (
    <div className={ groupActionClass } role="button" tabIndex={ 0 } onClick={ handleAction }>
      {buttonContent}
    </div>
  );
};

// Updated interface with camelCase property names to match camelizeKeys output
interface IManagementGroupResponse {
  managementGroups: Array<{
    id: TID;
    name: string;
    intercompanyEnabled: boolean;
    userId: TID;
    accessEnabled: boolean;
  }>;
  meta: {
    totalCount: number;
  };
}

// Extended interface to include the accessEnabled property
interface IExtendedAccessibleManagementGroup extends IAccessibleManagementGroup {
  accessEnabled: boolean;
}

// Define the API response interface
interface IManageAccessResponse {
  userManagementGroupSettingDetail: {
    id: TID;
    userId: TID;
    managementGroupId: TID;
    managementGroupName: string;
    accessEnabled: boolean;
  };
  meta: {
    success: boolean;
  };
}

const ManageGroupsModal = ({
  isOpen,
  onCancel,
}: IManageGroupsModalProps) => {
  // Set page size to 15 as requested
  const PAGE_SIZE = 15;
  const [page, setPage] = useState(1);
  const [filteredGroups, setFilteredGroups] = useState<IExtendedAccessibleManagementGroup[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef<HTMLTableCellElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const fetchController = useRef<AbortController | null>(null);

  // Debounce search query updates
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchQuery !== debouncedSearchQuery) {
        setDebouncedSearchQuery(searchQuery);
        setPage(1); // Reset to first page when search query changes
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, debouncedSearchQuery]);

  // Fetch groups data when modal is open or page/search changes
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    // Cancel previous fetch if it's still in progress
    if (fetchController.current) {
      fetchController.current.abort();
    }

    const abortController = new AbortController();
    fetchController.current = abortController;

    const fetchGroups = async () => {
      if (page === 1) {
        setIsLoading(true);
      } else {
        setIsSearching(true);
      }

      try {
        const params = {
          page,
          per_page: PAGE_SIZE,
          search:   debouncedSearchQuery || undefined,
        };

        // Using the standard apiGet helper from helpers.ts to ensure correct baseURL is used
        const response = await apiGet(
          '/api/v1/management_groups/for_current_user',
          underscoreKeys(params),
        );

        const data = camelizeKeys(response) as IManagementGroupResponse;

        const groupsData = data.managementGroups.map((group) => ({
          id:            group.id,
          name:          group.name,
          accessEnabled: group.accessEnabled,
        }));

        if (page === 1) {
          setFilteredGroups(groupsData);
        } else {
          setFilteredGroups((prev) => [...prev, ...groupsData]);
        }

        // Check if we've received fewer items than requested or if we've reached the total count
        const totalFetched = (page - 1) * PAGE_SIZE + groupsData.length;
        setHasMore(totalFetched < data.meta.totalCount && groupsData.length > 0);
      } catch (error: any) {
        // Only log errors that aren't from the abort controller
        if (error.name !== 'AbortError' && !axios.isCancel(error)) {
          // Silently handle errors without console.error
        }
      } finally {
        if (page === 1) {
          setIsLoading(false);
        } else {
          setIsSearching(false);
        }

        if (fetchController.current === abortController) {
          fetchController.current = null;
        }
      }
    };

    fetchGroups();

    return () => {
      abortController.abort();
    };
  }, [isOpen, page, debouncedSearchQuery]);

  // Reset state when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setPage(1);
      setFilteredGroups([]);
      setSearchQuery('');
      setDebouncedSearchQuery('');
      setHasMore(true);

      // Abort any in-progress requests
      if (fetchController.current) {
        fetchController.current.abort();
        fetchController.current = null;
      }
    }
  }, [isOpen]);

  // Intersection observer for infinite scrolling
  useEffect(() => {
    if (!hasMore || isLoading || isSearching) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !isLoading && !isSearching) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 0.5, rootMargin: '100px' },
    );

    const currentLoaderRef = loaderRef.current;
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [hasMore, isLoading, isSearching]);

  const handleJoinGroup = async (id: TID) => {
    try {
      setIsLoading(true);

      const response = await apiPost(
        `/api/v1/management_groups/${id}/manage_access`,
        underscoreKeys({ accessType: 'grant' }),
      );

      const responseData = camelizeKeys(response) as IManageAccessResponse;

      if (responseData.meta.success) {
        const accessEnabled = responseData.userManagementGroupSettingDetail.accessEnabled;
        setFilteredGroups((prev) => {
          const updated = [...prev];
          const index = updated.findIndex((group) => group.id === id);
          if (index !== -1) {
            updated[index] = { ...updated[index], accessEnabled };
          }
          return updated;
        });
      }
    } catch {
      // Silently handle errors without console.error
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeaveGroup = async (id: TID) => {
    try {
      setIsLoading(true);

      const response = await apiPost(
        `/api/v1/management_groups/${id}/manage_access`,
        underscoreKeys({ accessType: 'revoke' }),
      );

      const responseData = camelizeKeys(response) as IManageAccessResponse;

      if (responseData.meta.success) {
        const accessEnabled = responseData.userManagementGroupSettingDetail.accessEnabled;
        setFilteredGroups((prev) => {
          const updated = [...prev];
          const index = updated.findIndex((group) => group.id === id);
          if (index !== -1) {
            updated[index] = { ...updated[index], accessEnabled };
          }
          return updated;
        });
      }
    } catch {
      // Silently handle errors without console.error
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Modal.Standard
      cancelTitle="Close"
      className={ styles['management-group-modal'] }
      proceedTitle="Close"
      show={ isOpen }
      title="Management Groups"
      onCancel={ handleClose }
      onProceed={ handleClose }
    >
      {() => (
        <div className="p-15">
          <div className="m-b-15">
            Joining or leaving a group will manage your access to that group, and it will appear
            in the dropdown for easy selection.
          </div>

          <div className="m-b-15">
            <div className={ styles['search-container'] }>
              <div className={ styles['search-icon'] }>
                <SearchIcon fontSize={ 14 } />
              </div>
              <input
                className={ `form-control ${styles['search-input']}` }
                placeholder="Search group"
                type="text"
                value={ searchQuery }
                onChange={ handleSearchChange }
              />
            </div>
          </div>

          <div className={ styles['column-headers'] }>
            <div>Group Name</div>
            <div>Action</div>
          </div>

          {isLoading && page === 1 ? (
            <div className="text-center p-20">
              <ProgressActivityLoader size="large" />
            </div>
          ) : (
            <div className={ styles['groups-list-container'] }>
              <table className={ styles['modal-table'] }>
                <tbody>
                  {filteredGroups.map((group) => (
                    <tr key={ group.id }>
                      <td className={ styles['group-name-cell'] }>{group.name}</td>
                      <td className={ styles['group-action-cell'] }>
                        <GroupAction
                          isJoined={ group.accessEnabled }
                          managementGroup={ group }
                          onJoin={ handleJoinGroup }
                          onLeave={ handleLeaveGroup }
                        />
                      </td>
                    </tr>
                  ))}
                  {hasMore && (
                    <tr>
                      <td ref={ loaderRef } colSpan={ 2 } style={ { textAlign: 'center', padding: '15px', border: 'none' } }>
                        {isSearching ? (
                          <ProgressActivityLoader size="small" />
                        ) : (
                          <div style={ { height: '10px' } } />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </Modal.Standard>
  );
};

const useManageGroupsModal = makeUseModal(ManageGroupsModal);

export {
  IManageGroupsModalProps,
  useManageGroupsModal,
  ManageGroupsModal as default,
};
