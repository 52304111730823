import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateServiceDocumentSplitsParams,
  IGetServiceDocumentSplitsParams,
  IGetServiceDocumentSplitsResponse,
  createServiceDocumentSplits,
  getServiceDocumentSplits,
} from '@src/requests/accounts_payable/service_document_splits';

const useCreateServiceDocumentSplits = () => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, ICreateServiceDocumentSplitsParams >(
    createServiceDocumentSplits,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.accountsPayableServiceDocuments);
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
      },
    },
  );
};

const useGetServiceDocumentSplits = (params: IGetServiceDocumentSplitsParams) => {
  return useQuery<IGetServiceDocumentSplitsResponse, Error>(
    [QueryKey.serviceDocumentSplits, params.documentID],
    () => getServiceDocumentSplits(params),
    {
      staleTime: 0,
    },
  );
};

export {
  useCreateServiceDocumentSplits,
  useGetServiceDocumentSplits,
};
