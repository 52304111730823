import React, { useCallback, useEffect, useMemo } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useOperationalDashboardFayeChannel } from '@src/hooks/faye/operational_dashboard';
import { useGetBooksGptPostOnboardingState } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { TBooksGptPostOnboardingState } from '@src/types/businesses';
import { TID } from '@src/types/common';
import {
  ICollaborationProps,
  IExpenseProps,
  IReconciliationProps,
  IRevenueProps,
} from '@src/types/common_operational_dashboard';
import { IImportDataFromLedgerCompletedFayeEvent } from '@src/types/docyt_events/import_data_from_ledger_completed';
import { AuditScanDetails, TAuditScanStatus } from '@src/types/operational_dashboard/audit_scan';

import Table from '@src/components/ui_v2/table';

import BusinessCell from '../../components/custom_cells/business_cell';
import BusinessDriveCell from '../../components/custom_cells/business_drive_cell';
import CollaborationCell from '../../components/custom_cells/collaboration_cell';
import ExpenseCell from '../../components/custom_cells/expense_cell';
import IssueCell from '../../components/custom_cells/issue_cell';
import LinkedChatIconWithBadge from '../../components/custom_cells/linked_chat_icon_with_badge';
import MonthEndClosingCell from '../../components/custom_cells/month_end_closing_cell';
import ReconciliationCell from '../../components/custom_cells/reconciliation_cell';
import RevenueCell from '../../components/custom_cells/revenue_cell';
import { IBusinessOwnerConfigFormType } from '../../helper';
import { hasActivePreference } from '../../helper/functional';
import { hasNoData as checkNoData } from '../../helper/table.utils';
import {
  IDashboardData,
  IBusinessCommonProps,
  TSetupStatus,
} from '../../types';

interface ITableRowItemProps {
    columnData: IDashboardData;
    preferences?: IBusinessOwnerConfigFormType;
    viewNotes?: (businessId: TID) => void;
}

/**
 * Renders a single row in the operational dashboard table
 * Handles both data display and empty state rendering when needed
 */
const TableRowItem = ({
  columnData,
  preferences,
  viewNotes,
}: ITableRowItemProps) => {
  const queryClient = useQueryClient();
  // Create a reference to the table row element
  const rowRef = React.useRef<HTMLTableRowElement>(null);

  // State to track if the row is in view
  const [isInView, setIsInView] = React.useState<boolean>(false);

  // State to control whether audit scan polling should continue
  const [inProgressAuditScanPoll, setInProgressAuditScanPoll] = React.useState<boolean>(true);

  // Check if columnData is missing or empty - memoize this check
  const hasNoData = useMemo(() => checkNoData(columnData), [columnData]);

  // Memoize business ID for performance
  const businessId = useMemo(
    () => (hasNoData ? 0 : (columnData?.businessId || 0)),
    [hasNoData, columnData?.businessId],
  );

  // Fetch the books_gpt_post_onboarding_state for the business
  const { data: booksGptPostOnboardingState } = useGetBooksGptPostOnboardingState(
    businessId,
    {
      // Only enable the query when necessary conditions are met
      enabled: !hasNoData && !!businessId && columnData.isSetupCompleted,
    },
    (isInView && inProgressAuditScanPoll && !hasNoData),
  );

  const handleImportDataFromLedgerCompleted = useCallback((event: IImportDataFromLedgerCompletedFayeEvent) => {
    if (!hasNoData && event.event.businessId === businessId) {
      queryClient.invalidateQueries([QueryKey.operationDashboardTableData]);
    }
  }, [hasNoData, businessId, queryClient]);

  useOperationalDashboardFayeChannel(businessId, handleImportDataFromLedgerCompleted);

  // Memoize the display name for intersection observer dependency
  const displayNameForObserver = useMemo(
    () => {
      return hasNoData ? '' : columnData?.displayName;
    },
    [hasNoData, columnData?.displayName],
  );

  // Set up intersection observer
  useEffect(() => {
    const currentRef = rowRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
    );
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [displayNameForObserver]);

  // Update polling state whenever we get new data
  useEffect(() => {
    if (booksGptPostOnboardingState?.state) {
      // Continue polling if in progress states
      const inProgress = [
        'scan_in_progress',
        'close_in_progress',
        'learn_in_progress',
        'reset_in_progress',
      ];
      const shouldKeepPolling = inProgress.includes(booksGptPostOnboardingState.state as string);
      setInProgressAuditScanPoll(shouldKeepPolling);
    }
  }, [booksGptPostOnboardingState]);

  // Memoized cell rendering functions
  const memoizedBusinesCell = useCallback((columnDataItem?: IBusinessCommonProps): React.ReactNode => {
    // Type cast outside of JSX to avoid linter issues
    const auditScanTyped = booksGptPostOnboardingState?.auditScan as unknown as AuditScanDetails;
    return (
      <BusinessCell
        { ...columnDataItem }
        auditScan={ auditScanTyped }
        booksGptPostOnboardingState={ booksGptPostOnboardingState?.state as TBooksGptPostOnboardingState }
      />
    );
  }, [booksGptPostOnboardingState]);

  const memoizedBusinessDriveCell = useCallback((columnDataItem?: IBusinessCommonProps): React.ReactNode => (
    <BusinessDriveCell { ...columnDataItem } />
  ), []);

  const handleNotesViewClick = useCallback(() => {
    if (!hasNoData && businessId) {
      viewNotes?.(businessId);
    }
  }, [hasNoData, businessId, viewNotes]);

  const memoizedIssueCell = useCallback((
    columnDataItem?: IDashboardData,
  ): React.ReactNode => (
    <IssueCell
      businessId={ columnDataItem?.businessId }
      businessIntent={ columnDataItem?.businessIntent }
      issuesData={ columnDataItem?.issues }
      preferences={ preferences }
      setupStatus={ columnDataItem?.setupStatus }
    />
  ), [preferences]);

  const memoizedExpenseCell = useCallback((
    columnDataItem?: IExpenseProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <ExpenseCell
      businessId={ columnDataItem?.businessId }
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  ), [preferences]);

  const memoizedRevenueCell = useCallback((
    columnDataItem?: IRevenueProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <RevenueCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  ), [preferences]);

  const memoizedReconciliationCell = useCallback((
    columnDataItem?: IReconciliationProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <ReconciliationCell
      businessId={ columnDataItem?.businessId }
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  ), [preferences]);

  const memoizedMonthEndCell = useCallback((columnDataItem?: IDashboardData): React.ReactNode => {
    // Type cast outside of JSX to avoid linter issues
    const auditScanStatus = booksGptPostOnboardingState?.auditScan?.status as TAuditScanStatus;

    return (
      <MonthEndClosingCell
        auditId={ booksGptPostOnboardingState?.auditScan?.id }
        auditScan={ booksGptPostOnboardingState?.meta?.openAnomaliesCount }
        auditScanStatus={ auditScanStatus }
        auditStartDate={ booksGptPostOnboardingState?.auditScan?.startDate }
        businessId={ columnData?.businessId }
        businessIntent={ columnData?.businessIntent }
        businessName={ columnData?.displayName }
        disconnectedGeneralLedger={ columnDataItem?.issues?.disconnectedGeneralLedger ?? false }
        handleNotesViewClick={ handleNotesViewClick }
        lastAuditScanDate={ booksGptPostOnboardingState?.auditScan?.updatedAt }
        lastBookLockDate={ booksGptPostOnboardingState?.meta?.lastBooksLockDate }
        lastCloseDate={ booksGptPostOnboardingState?.meta?.lastCloseDate }
        missingBankStatements={ columnDataItem?.monthEndClosing?.missingBankStatements }
        notes={ booksGptPostOnboardingState?.meta?.lastCloseNote }
        preferences={ preferences }
        booksGptPostOnboardingState={ booksGptPostOnboardingState?.state as TBooksGptPostOnboardingState }
        booksGptPostOnboardingMeta={ booksGptPostOnboardingState?.meta }
      />
    );
  }, [
    preferences,
    booksGptPostOnboardingState?.auditScan?.startDate,
    booksGptPostOnboardingState?.auditScan?.updatedAt,
    booksGptPostOnboardingState?.auditScan?.status,
    booksGptPostOnboardingState?.meta,
    booksGptPostOnboardingState?.auditScan?.id,
    booksGptPostOnboardingState?.state,
    columnData?.businessId,
    columnData?.displayName,
    columnData?.businessIntent,
    handleNotesViewClick,
  ]);

  const memoizedCollaborationCell = useCallback((
    columnDataItem?: ICollaborationProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <CollaborationCell
      businessId={ columnDataItem?.businessId }
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  ), [preferences]);

  return (
    <Table.Row ref={ rowRef }>
      <Table.Cell>
        { memoizedBusinesCell(columnData) }
      </Table.Cell>
      {hasActivePreference('businessChat', preferences) && (
        <Table.Cell>
          <LinkedChatIconWithBadge
            businessId={ businessId ?? 0 }
            unreadCount={ columnData?.unreadBusinessChatCount ?? 0 }
          />
        </Table.Cell>
      )}
      {hasActivePreference('mailroom', preferences) && (
        <Table.Cell>
          { memoizedBusinessDriveCell({
            unreadInboxMessages: columnData?.unreadInboxMessages,
            businessId,
          }) }
        </Table.Cell>
      )}
      {hasActivePreference('issues', preferences) && (
        <Table.Cell>
          { memoizedIssueCell(columnData) }
        </Table.Cell>
      )}
      {hasActivePreference('expense', preferences) && (
        <Table.Cell>
          { memoizedExpenseCell({ ...columnData.expense, businessId }, columnData?.setupStatus) }
        </Table.Cell>
      )}
      {hasActivePreference('revenue', preferences) && (
        <Table.Cell>
          { memoizedRevenueCell(columnData.revenue, columnData.setupStatus) }
        </Table.Cell>
      )}
      {hasActivePreference('continuousReconciliation', preferences) && (
        <Table.Cell>
          { memoizedReconciliationCell({
            ...columnData.continuousReconciliation,
            businessId,
          }, columnData?.setupStatus) }
        </Table.Cell>
      )}
      {hasActivePreference('monthEndClosing', preferences) && (
        <Table.Cell>
          { memoizedMonthEndCell(columnData) }
        </Table.Cell>
      )}
      {hasActivePreference('collaboration', preferences) && (
        <Table.Cell>
          { memoizedCollaborationCell({
            ...columnData.collaboration,
            businessId,
          }, columnData?.setupStatus) }
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default React.memo(TableRowItem);
