import { TID } from '@src/types/common';
import { AuditScan, AuditScanDetails, IAuditTransactionData, Meta } from '@src/types/operational_dashboard/audit_scan';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from '../helpers';

interface IGetLatestAuditScansParams {
  businessId?: TID;
}

interface IGetLatestAuditScansResponse {
  auditScans: AuditScan[];
}

interface IRunAuditScanParams {
  businessId: TID;
}

interface ICloseAuditScanParams {
  id: TID;
  closeDate: string;
  notes: string;
  businessId: TID;
}

interface IGetAuditScanNotesParams {
  businessId: TID;
}

interface IGetAuditScanNotesResponseInfinite {
  meta: {
    page: number;
    totalCount: number;
  };
  collection: AuditScanDetails[];
}

interface IGetAuditTransactionsParams {
  businessId?: TID;
  anomaly?: boolean;
  page?: number;
  perPage?: number;
  filters?: object;
}

interface IGetAuditTransactionsResponse {
  meta: {
    page: number;
    totalCount: number;
  };
  collection: IAuditTransactionData[];
}

interface IGetBooksGptPostOnboardingStateParams {
  businessId?: TID;
}

interface IGetBooksGptPostOnboardingStateResponse {
  state: string;
  auditScan?: AuditScanDetails;
  meta: Meta;
}

interface IReopenBooksParams {
  businessId: TID;
}

interface IReopenBooksResponse {
  businessId: TID;
  booksGptPostOnboardingState: number;
  stateName: string;
}

const getLatestAuditScans = (params: IGetLatestAuditScansParams): Promise<AuditScan> => {
  return apiGet('/api/v1/audit_scans/latest', { business_id: params.businessId })
    .then((data) => {
      const response = <IGetLatestAuditScansResponse>camelizeKeys(data);
      return response.auditScans[0] as AuditScan;
    });
};

const getAuditScanNotes = (params: IGetAuditScanNotesParams): Promise<IGetAuditScanNotesResponseInfinite> => {
  return apiGet('/api/v1/audit_scans', { business_id: params.businessId })
    .then((data) => {
      const response = <any>camelizeKeys(data);
      return {
        meta:       response.meta,
        collection: response.auditScans,
      } as IGetAuditScanNotesResponseInfinite;
    });
};

const getBooksGptPostOnboardingState = async (
  params: IGetBooksGptPostOnboardingStateParams,
): Promise<IGetBooksGptPostOnboardingStateResponse> => {
  const url = `/api/v1/businesses/${params.businessId}/books_gpt`;
  const response = await apiGet(url);
  const camelizedData = camelizeKeys(response.data) as IGetBooksGptPostOnboardingStateResponse;
  return camelizedData;
};

const runAuditScan = async (params: IRunAuditScanParams): Promise<any> => {
  // First run the audit scan
  const response = await apiPost(
    '/api/v1/audit_scans',
    underscoreKeys({ business_id: params.businessId }),
  );

  // After successful scan, immediately fetch the updated post onboarding state
  try {
    const scanResponse = camelizeKeys(response) as AuditScan;
    // Fetch the updated state and return it along with the scan response
    const postOnboardingState = await getBooksGptPostOnboardingState({ businessId: params.businessId });
    return {
      scanResponse,
      postOnboardingState,
    };
  } catch (error) {
    console.error('Error fetching post onboarding state after scan:', error);
    // Return the original response even if the follow-up fetch fails
    return {
      scanResponse: camelizeKeys(response) as AuditScan,
      postOnboardingState: null,
    };
  }
};

const closeAuditScan = async (params: ICloseAuditScanParams): Promise<any> => {
  try {
    // First close the audit scan
    await apiPut(`/api/v1/audit_scans/${params.id}/close`, {
      close_date:  params.closeDate,
      note:        params.notes,
      business_id: params.businessId,
    });

    // After successful close, immediately fetch the updated post onboarding state
    const postOnboardingState = await getBooksGptPostOnboardingState({ businessId: params.businessId });

    return {
      success: true,
      postOnboardingState,
    };
  } catch (error) {
    console.error('Error in closeAuditScan:', error);
    throw error;
  }
};

const getAuditTransactions = (
  params: IGetAuditTransactionsParams,
): Promise<IGetAuditTransactionsResponse> => {
  return apiGet(
    '/api/v1/audit_transactions',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      ...cdata,
      collection: cdata.auditTransactions,
    } as IGetAuditTransactionsResponse;
  });
};

const reopenBooks = (params: IReopenBooksParams): Promise<IGetBooksGptPostOnboardingStateResponse> => {
  const url = `/api/v1/businesses/${params.businessId}/books_gpt`;
  return apiPut(
    url,
    {
      state: 'reset_in_progress',
    },
  ).then((response) => {
    return camelizeKeys(response.data) as IGetBooksGptPostOnboardingStateResponse;
  });
};

export {
  getLatestAuditScans,
  getAuditScanNotes,
  runAuditScan,
  IGetAuditScanNotesParams,
  IGetAuditScanNotesResponseInfinite,
  IGetLatestAuditScansParams,
  IGetLatestAuditScansResponse,
  IRunAuditScanParams,
  closeAuditScan,
  getAuditTransactions,
  IGetAuditTransactionsParams,
  IGetAuditTransactionsResponse,
  getBooksGptPostOnboardingState,
  IGetBooksGptPostOnboardingStateParams,
  IGetBooksGptPostOnboardingStateResponse,
  reopenBooks,
  IReopenBooksParams,
  IReopenBooksResponse,
};
