import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import { CaretIcon } from '@src/components/utils/fa_icons';

import { TUnifiedDropdownOption } from '../types';

import styles from '../styles.module.scss';

interface IMenuItemProps {
  item: TUnifiedDropdownOption,
  path?: string[],
  onSelect: (option: TUnifiedDropdownOption) => void,
  activePath: string[],
  setActivePath: (currentPath: string[]) => void,
}

const MenuItem = ({
  item,
  path = [],
  onSelect,
  activePath,
  setActivePath,
}: IMenuItemProps) => {
  const currentPath = useMemo(() => [...path, item.value], [path, item.value]);
  const isActive = useMemo(
    () => activePath.length >= currentPath.length
                   && activePath.slice(0, currentPath.length).every((v, i) => v === currentPath[i]),
    [activePath, currentPath],
  );

  // State to keep track of mouse status
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);

    // For all menu items with submenus, activate them
    if (item.subMenuItems && item.subMenuItems.length > 0) {
      setActivePath(currentPath);
    }

    // Special handling for management groups actions
    // We only want to override path behavior for action items like "New Group" and "Manage"
    const isActionItem = item.type.startsWith('action-');
    const isManagementGroupsParent = currentPath[0] === 'management-groups';

    if (isManagementGroupsParent && isActionItem) {
      // For action items, keep the management groups menu open
      // but don't interfere with regular menu items that need submenus
      setActivePath(['management-groups']);
    }
  }, [item.subMenuItems, item.type, currentPath, setActivePath]);

  const handleMouseLeave = useCallback((e) => {
    setIsHovered(false);

    // Get parent elements that could be related targets
    const relatedTarget = e.relatedTarget;
    const managementGroupsContainer = document.querySelector(`.${styles['section-items']}`)?.closest(`.${styles['section-container']}`);

    // Only reset path if we're truly leaving the dropdown area
    if (item.subMenuItems && item.subMenuItems.length > 0) {
      // Don't reset if moving to another item in the same container
      const isMovingWithinContainer = relatedTarget
        && (managementGroupsContainer?.contains(relatedTarget as Node)
         || (relatedTarget as Element).closest(`.${styles['section-item']}`)
         || (relatedTarget as Element).closest(`.${styles['section-items']}`));

      if (!isMovingWithinContainer) {
        // Only reset if not moving within the same container
        setActivePath(['businesses']);
      }
    }
  }, [setActivePath, item.subMenuItems]);

  const handleClick = useCallback(() => {
    if (!item.subMenuItems || item.subMenuItems.length === 0) {
      onSelect(item);
    }
  }, [item, onSelect]);

  return (
    <div
      key={ item.value }
      className={ classNames(
        styles['section-item'],
        {
          [styles.active]:           isActive || isHovered,
          [styles['bottom-border']]: item.type === 'action-add',
        },
      ) }
      data-type={ item.type }
      data-value={ item.value }
      role="button"
      tabIndex={ 0 }
      onClick={ handleClick }
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
    >
      <div className={ classNames(
        styles['item-content'],
        { [styles['item-content-with-submenu']]: item.subMenuItems },
      ) }
      >
        {item.icon && <span className={ styles['item-icon'] }>{item.icon}</span>}
        <span className={ styles['item-label'] }>{item.label}</span>
        {item.subMenuItems && (
          <span className={ styles['arrow-icon'] }>
            <CaretIcon variant="right" />
          </span>
        )}
      </div>

      {/* Submenu that appears when active */}
      {item.subMenuItems && isActive && (
        <div
          className={ styles['submenu-container'] }
          onMouseEnter={ () => setActivePath(currentPath) }
        >
          {item.subMenuItems.map((subItem) => (
            <MenuItem
              key={ subItem.value }
              activePath={ activePath }
              item={ subItem }
              path={ currentPath }
              setActivePath={ setActivePath }
              onSelect={ onSelect }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
