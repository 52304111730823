import React, { memo, useCallback } from 'react';

import { ICommonTooltipProps, IListItem } from '@src/components/operational_dashboard/types';
import { Button } from '@src/components/ui_v2/buttons';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

interface IAuditScanTooltipProps extends ICommonTooltipProps {
  item: IListItem;
  auditScanCount: number;
  lastAuditedOn?: string | number;
  businessName?: string;
  openModal?: () => void;
}
const AuditScanTooltip: React.FC<IAuditScanTooltipProps> = ({
  title,
  item,
  auditScanCount = 0,
  lastAuditedOn,
  openModal = () => {},
}) => {
  return (
    <TooltipWrapper
      title={ title }
    >
      <TooltipItem key={ item.id }>
        <div className={ styles['tooltip-audit-scan-item-main'] }>
          <div className={ styles['tooltip-audit-scan-item-heading'] }>
            {auditScanCount > 0 ? `${auditScanCount} Anomalies found` : 'No anomalies found'}
            <span className={ styles['tooltip-audit-scan-item-subheading'] }>
              Last audited on
              {' '}
              {lastAuditedOn}
            </span>
          </div>
          {(item.actionLink && auditScanCount >= 0) && (
          <div className={ styles['tooltip-audit-scan-item-action'] }>
            <Button
              variant="link"
              onClick={ openModal }
            >
              Review
            </Button>
          </div>
          )}
        </div>
      </TooltipItem>
    </TooltipWrapper>

  );
};
export default memo(AuditScanTooltip);
