import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getLatestAuditScans,
  runAuditScan,
  closeAuditScan,
  getAuditScanNotes,
  IGetAuditScanNotesParams,
  IGetAuditScanNotesResponseInfinite,
  getAuditTransactions,
  IGetAuditTransactionsParams,
  IGetAuditTransactionsResponse,
  getBooksGptPostOnboardingState,
  IGetBooksGptPostOnboardingStateResponse,
} from '@src/requests/operational_dashboard/audit_scan';
import { TID } from '@src/types/common';
import { AuditScan,
  ICloseAuditScanParams,
  AuditScanDetails,
  IAuditTransactionData } from '@src/types/operational_dashboard/audit_scan';

import { createUseGetInfiniteCollection } from '../infinite_collection_queries';

const POLLING_INTERVAL = 10000; // 10 seconds

const useGetBooksGptPostOnboardingState = (
  businessId: TID | undefined,
  options?: UseQueryOptions<IGetBooksGptPostOnboardingStateResponse, Error>,
  shouldPoll: boolean = true,
) => {
  return useQuery<IGetBooksGptPostOnboardingStateResponse, Error>(
    [QueryKey.booksGptPostOnboardingState, businessId],
    () => getBooksGptPostOnboardingState({ businessId }),
    {
      ...options,
      refetchInterval:             shouldPoll ? POLLING_INTERVAL : false,
      refetchOnWindowFocus:        false,
      refetchIntervalInBackground: false,
      staleTime:                   10000, // Data is fresh for 10s
    },
  );
};

const useGetLatestAuditScans = (
  businessId: TID | undefined,
  options?: UseQueryOptions<AuditScan, Error>,
  shouldPoll: boolean = true,
) => {
  return useQuery<AuditScan, Error>(
    [QueryKey.auditScans, businessId],
    () => getLatestAuditScans({ businessId }),
    {
      refetchInterval:             shouldPoll ? POLLING_INTERVAL : false,
      refetchOnWindowFocus:        false,
      refetchIntervalInBackground: false,
      staleTime:                   60000, // Data is fresh for 1 minute
      ...options,
    },
  );
};

const useRunAuditScan = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error & AxiosError, { businessId: TID }>(
    runAuditScan,
    {
      onSuccess: (data, { businessId }) => {
        // Invalidate the audit scans cache
        queryClient.invalidateQueries([QueryKey.auditScans, businessId]);

        // If we received a post onboarding state, update the cache with it
        if (data?.postOnboardingState) {
          queryClient.setQueryData(
            [QueryKey.booksGptPostOnboardingState, businessId],
            data.postOnboardingState,
          );
        } else {
          // Otherwise just invalidate to trigger a refetch
          queryClient.invalidateQueries([QueryKey.booksGptPostOnboardingState, businessId]);
        }
      },
    },
  );
};

const useCloseAuditScan = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, ICloseAuditScanParams & { businessId: TID }>(
    (data) => closeAuditScan(data),
    {
      onSuccess: (data, { businessId }) => {
        // Invalidate the audit scans cache
        queryClient.invalidateQueries([QueryKey.auditScans, businessId]);

        // If we received a post onboarding state, update the cache with it
        if (data?.postOnboardingState) {
          queryClient.setQueryData(
            [QueryKey.booksGptPostOnboardingState, businessId],
            data.postOnboardingState,
          );
        } else {
          // Otherwise just invalidate to trigger a refetch
          queryClient.invalidateQueries([QueryKey.booksGptPostOnboardingState, businessId]);
        }
      },
    },
  );
};

const useGetAuditScanNotesInfiniteScroll = createUseGetInfiniteCollection<
  AuditScanDetails,
  IGetAuditScanNotesParams,
  IGetAuditScanNotesResponseInfinite
>({
  queryKey: QueryKey.auditScanNotes,
  request:  getAuditScanNotes,
});

const useGetAuditTransactions = createUseGetInfiniteCollection<
  IAuditTransactionData,
  IGetAuditTransactionsParams,
  IGetAuditTransactionsResponse
>({
  queryKey: QueryKey.auditScanTransactions,
  request:  getAuditTransactions,
});

export {
  useGetAuditScanNotesInfiniteScroll,
  useGetLatestAuditScans,
  useRunAuditScan,
  useCloseAuditScan,
  useGetAuditTransactions,
  useGetBooksGptPostOnboardingState,
};
