import toastr from '@lib/toastr';
import { TMongoID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';
import { IReportItem, IItemAccount } from '@src/types/report_service/report_item';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';
import { getReportBTFEndPoint } from '@src/utils/config';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';
import { isDcServiceReport } from '@src/components/business_reports/custom_reports/hooks';

import { apiGet } from '../helpers';

interface IGetItemLineItemDetailsParams {
  reportDataId: TMongoID;
  itemId: TMongoID;
  columnId: TMongoID;
  chartOfAccountId: number;
  accountingClassId?: number;
  page: number;
}

const getReportItems = (reportId: TMongoID, endPoint: string): Promise<IReportItem[]> => {
  const finalEndPoint = endPoint || getReportBTFEndPoint(
    window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.REPORT_ITEMS,
  );
  return apiGet(
    `/${finalEndPoint}/api/v1/reports/${reportId}/items`,
  ).then((data) => camelizeKeys(data.items) as IReportItem[]);
};

const getReportByIdentifier = (reportId: string, reportIdentifier: string): Promise<IReportItem> => {
  return apiGet(
    `/reports/api/v1/reports/${reportId}/items/by_identifier?item_identifier=${reportIdentifier}`,
  ).then((data) => camelizeKeys(data.item) as IReportItem);
};

const getItemLineItemDetails = (
  report: IReport,
  item: IItemAccount,
  page: number,
): Promise<IReportServiceQBOLineItemDetails[]> => {
  let endPoint = '';
  if (isDcServiceReport(report?.slug)) {
    endPoint = 'report-dc';
  } else {
    endPoint = 'reports';
  }
  const params: IGetItemLineItemDetailsParams = {
    reportDataId:     item.reportDataId,
    itemId:           item.itemId,
    columnId:         item.columnId,
    chartOfAccountId: item.chartOfAccountId,
    page,
  };
  if (item.accountingClassId) {
    params.accountingClassId = item.accountingClassId;
  }
  return apiGet(
    `/${endPoint}/api/v1/report_datas/${item.reportDataId}/item_account_values/line_item_details`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.line_item_details) as IReportServiceQBOLineItemDetails[]).catch((err: Error) => {
    toastr.error(`Error: ${err.message}`, 'Error');
    throw err; // Re-throw error to maintain Promise<IReportServiceQBOLineItemDetails[]> return type
  });
};

export {
  getReportItems,
  getReportByIdentifier,
  getItemLineItemDetails,
};
