import React, { ReactNode, useMemo, useCallback } from 'react';

import { useCloseAuditScan } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { TBooksGptPostOnboardingState } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';
import { WarningTriangleIcon } from '@src/components/utils/icomoon';

import HoverTooltipWrapper from '../hover_tooltip_wrapper';

import styles from './style.module.scss';

interface ReRunAuditScanButtonProps {
  status?: TBooksGptPostOnboardingState;
  onRunAudit: () => void;
  businessId: TID;
  auditId?: TID;
}

const ReRunAuditScanButton = ({ onRunAudit, status, businessId, auditId }: ReRunAuditScanButtonProps) => {
  const { mutate: closeAuditScan } = useCloseAuditScan();

  const handleClick = useCallback(() => {
    if (status === 'close_failed' && auditId) {
      // If closing books failed, attempt to close audit scan again
      closeAuditScan({
        businessId,
        id:        auditId,
        closeDate: '', // Empty string for server to use existing value
        notes:     '', // Empty string for server to use existing value
      });
    } else {
      // For other states, use the original onRunAudit handler
      onRunAudit();
    }
  }, [status, businessId, auditId, closeAuditScan, onRunAudit]);

  const toolTipContent:ReactNode = useMemo(() => {
    if (status === 'scan_failed') {
      return (
        <div>
          Audit scan failed. Click to retry and run the audit again.
        </div>
      );
    }
    if (status === 'close_failed') {
      return (
        <div>
          Closing books failed. Click to retry.
        </div>
      );
    }
    if (status === 'learn_failed') {
      return (
        <div>
          Incremental learning failed. Click to retry.
        </div>
      );
    }
    return null;
  }, [status]);

  const getButtonText = (): string => {
    switch (status) {
      case 'close_failed':
        return 'Retry Closing';
      case 'learn_failed':
        return 'Retry Incremental Learning';
      case 'reset_failed':
        return 'Re-run Audit Scan';
      default:
        return 'Re-run Audit Scan';
    }
  };

  return (
    <HoverTooltipWrapper
      content={ toolTipContent }
    >
      <Button
        className={ styles['re-run-audit-scan-btn'] }
        size="compact"
        variant="outline"
        onClick={ handleClick }
      >
        <WarningTriangleIcon fontSize={ 20 } />
        {getButtonText()}
      </Button>
    </HoverTooltipWrapper>
  );
};

export default ReRunAuditScanButton;
