import React, { useMemo } from 'react';

import {
  TBooksGptOnboardingState,
  TBooksGptPostOnboardingState,
  TFirmSuiteOnboardingState,
} from '@src/types/businesses';

import { loaderTooltipTextBasedOnStatus } from '@src/components/operational_dashboard/helper/constants';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';
import { DocytBotAi } from '@src/components/utils/icomoon';

import HoverTooltipWrapper from '../hover_tooltip_wrapper';

import styles from './style.module.scss';

// Only use the business state types
type TLoaderStatus =
  | TFirmSuiteOnboardingState
  | TBooksGptOnboardingState
  | TBooksGptPostOnboardingState;

interface IProgressActionLoaderProps {
  status: TLoaderStatus;
}

const ProgressActionLoader = ({ status }: IProgressActionLoaderProps): JSX.Element | null => {
  const loaderTooltipText = useMemo(() => {
    const statusString = String(status);
    return loaderTooltipTextBasedOnStatus[statusString] || null;
  }, [status]);

  // Check if we should show the AI icon based on status
  const showAiIcon = useMemo(() => {
    const statusString = String(status);
    return statusString === 'in_ledger_setup'
      || statusString === 'close_in_progress'
      || statusString === 'reset_in_progress'
      || statusString === 'learn_in_progress'
      || statusString === 'scan_in_progress';
  }, [status]);

  return (
    <HoverTooltipWrapper
      content={ (
        <div className={ styles['loader-tooltip-content'] }>
          { showAiIcon && (
            <div className={ styles['loader-tooltip-icon'] }>
              <DocytBotAi fontSize={ 18 } />
            </div>
          ) }
          <div className={ styles['loader-tooltip-text'] }>
            { loaderTooltipText }
          </div>
        </div>
      ) }
    >
      <ProgressActivityLoader size="large" />
    </HoverTooltipWrapper>
  );
};

export default ProgressActionLoader;
